import angular from 'angular'

class LoanCalculatorController {
  calculator
  brand
  mode
  version
  calculatorUrl

  /*@ngInject*/
  constructor($rootScope, $sce, $window, $document, featureTogglesService, api, calculatorService) {
    this.$rootScope = $rootScope
    this.$sce = $sce
    this.$window = $window
    this.$document = $document
    this.featureTogglesService = featureTogglesService
    this.api = api
    this.calculatorService = calculatorService
  }

  async $onInit() {
    this.calculatorService.onBrokerChange((session) => {
      // TODO show the calculator iframe, and then pass the session tokens to the
      //  calculator iframe so that it can determine if it should render or not
    })

    this.updateEmbeddedHeight()
    await this.setCalculatorUrl()
  }

  async setCalculatorUrl() {
    if (!this.calculator || !this.brand) {
      return
    }

    const calculatorUi = await this.api.linkFor('calculatorUi')

    const parameters = new URLSearchParams()
    // Borrower is the default mode so this prevents exposing the mode parameter in public locations.
    if (this.mode && this.mode !== 'borrower') {
      parameters.set('mode', this.mode)
    }
    if (this.version) {
      parameters.set('version', this.version)
    }

    const queryString = parameters.toString()

    this.calculatorUrl = this.$sce.trustAsResourceUrl(
      `${calculatorUi.href}/${this.calculator}/${this.brand}${queryString ? `?${queryString}` : ''}`
    )
  }

  updateEmbeddedHeight() {
    this.$window.addEventListener('message', (event) => {
      // TODO send an object instead of a string
      const parts = event.data.split(':')
      const label = parts[0]
      const value = parseInt(parts[1], 10)

      if (label === 'calculatorHeight') {
        angular.element(this.$document.find('#calculator-iframe'))[0].style.height = `${value}px`
      }
    })
  }

  isEnabled() {
    return this.featureTogglesService.can('enableRideshareCalculator')
  }
}

export default LoanCalculatorController
