class BigfootController {
  /*@ngInject*/
  constructor(contentful) {
    this.name = 'footer'

    this.$onInit = () => {
      contentful.entries(`content_type=page&fields.name=${this.name}&include=10`).then((entry) => {
        const fields = entry.data && entry.data.items.length && entry.data.items[0].fields

        fields.components.forEach((component) => {
          // TODO create a substitution service via marketingContent.controller to handle this if we need to do it again.
          component.fields.text = component.fields.text.replace('__CURRENT_YEAR__', new Date().getFullYear())
        })

        this.page = fields
      })
    }
  }
}

export default BigfootController
