/**
 * @name CalculatorService
 * @desc Listens for events related to the displaying of Calculators and provides a mechanism
 * for components to subscribe to those events.
 *
 * Components can't directly listen for events from the Cognito service because
 * the load order isn't guaranteed, so this service instantiates early in the
 * Angular lifecycle to act as an intermediary.
 */
export default class CalculatorService {
  brokerSession = null
  listeners = []

  /*@ngInject*/
  constructor($rootScope) {
    $rootScope.$on('accredited-broker', (event, session) => {
      this.brokerSession = session
      this.notifyListeners(session)
    })
  }

  notifyListeners(data) {
    this.listeners.forEach(function(callback) {
      callback(data)
    })
  }

  onBrokerChange(callback) {
    this.listeners.push(callback)

    if (this.brokerSession) {
      callback(this.brokerSession)
    }

    return () => {
      const index = this.listeners.indexOf(callback)
      if (index > -1) {
        this.listeners.splice(index, 1)
      }
    }
  }
}
