export default class CognitoService {
  /*@ngInject*/
  constructor($rootScope, awsAmplifyService) {
    this.$rootScope = $rootScope
    this.awsAmplifyService = awsAmplifyService

    this.awsAmplifyService.configure()
  }

  async isSignedIn() {
    try {
      return await this.awsAmplifyService.getCurrentUser()
    } catch (error) {
      return false
    }
  }

  async signIn(username, password) {
    let _isSignedIn = false

    try {
      const {isSignedIn} = await this.awsAmplifyService.signIn({
        username,
        password
      })
      _isSignedIn = isSignedIn
    } catch (error) {
      if (typeof error === 'string' && error.startsWith('UserAlreadyAuthenticatedException')) {
        _isSignedIn = true
      } else {
        console.error('Failed to sign in', error)
      }
    }

    if (_isSignedIn) {
      console.log('Signed in')
      await this.verifyAccreditationStatus()
    } else {
      console.log('Failed to sign in')
    }
  }

  async verifyAccreditationStatus() {
    const session = await this.awsAmplifyService.fetchAuthSession()

    const idToken = session.tokens.idToken

    const brokerStatus = idToken.payload['custom:status']

    switch (brokerStatus) {
      case 'accredited':
        this.$rootScope.$emit('accredited-broker', session)
        break
      case 'blacklisted':
        await this.awsAmplifyService.signOut()
        break
      default:
        break
    }
  }
}
